import Layout from '../components/layout'
import ListItem from '../components/list-item'
import React from 'react';
import styled from '@emotion/styled'
import { Helmet } from 'react-helmet';
import { graphql } from "gatsby"

const Wrapper = styled('div')({
  display: 'flex',
  flexFlow: 'row wrap',
  width: '100%',
  height: '100%',
});

export const query = graphql`
  query {
    recepce8: file(relativePath: { eq: "recepce8/recepce8-1.jpg" }) { ...fluidImage }
    recepce10: file(relativePath: { eq: "recepce10/recepce10-4.jpg" }) { ...fluidImage }
    minibyt: file(relativePath: { eq: "mini-byt/mini-1.jpg" }) { ...fluidImage }
    podkrovniByt: file(relativePath: { eq: "podkrovni-byt/byt-1.jpg" }) { ...fluidImage }
    galerieKartografie: file(relativePath: { eq: "galerie-kartografie/gal-1.jpg" }) { ...fluidImage }
    podkrovniAtelier: file(relativePath: { eq: "podkrovni-atelier/atelier-1.jpg" }) { ...fluidImage }
  }
`

const ProjectsPage = ({ data }) => (
  <Layout>
    <Helmet>
      <title>Realizace</title>
      <meta name="description" content="Ing. arch. Marek Svoboda architektura 3D realizace interiéry byty exteriéry" />
    </Helmet>
    <Wrapper>
      <ListItem image={data.podkrovniByt.childImageSharp.fluid} link='/realizace/podkrovni-byt' name='Podkrovní byt' borderBottom />
      <ListItem image={data.galerieKartografie.childImageSharp.fluid} link='/realizace/galerie-kartografie' name='Galerie Kartografie' borderBottom />
      <ListItem image={data.minibyt.childImageSharp.fluid} link='/realizace/minibyt' name='Mini byt' borderBottom />
      <ListItem image={data.recepce8.childImageSharp.fluid} link='/realizace/recepce8' name='Recepce 8' borderBottom />
      <ListItem image={data.recepce10.childImageSharp.fluid} link='/realizace/recepce10' name='Recepce 10' borderBottom />
      <ListItem image={data.podkrovniAtelier.childImageSharp.fluid} link='/realizace/podkrovni-atelier' name='Podkrovní ateliér' />
    </Wrapper>
  </Layout>
)

export default ProjectsPage
